import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import parse from 'html-react-parser';
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container";
import { SectionA, styles} from "../components/styles";

const Image = styled.img`
  height: 400px;
  width: 100%;
  z-index: 0;
  object-fit: contain;
`

const Date = styled.span`
  color: #fff;
  z-index: 1;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .1em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
  margin: 0;
  background-color: ${styles.colorSecondary};
  padding: 5px;
`

const Content = styled.div`
  padding: 15px 0;

  a {
    color: ${styles.colorSecondary};
    text-decoration: none;
  }
`;

class Post extends Component {
  render() {
    const post = this.props.data.wordpressPost
    const title = parse(post.title);
    const content = parse(post.content);
    const date = formatDate(post.date);
    const articlePhoto = post.featured_media.source_url;

    return (
      <Layout>
        <SectionA p={"141px 0 30px"} pAM={"183px 0 30px"} pXL={"225px 0 30px"}>
          <Container>
            <SEO title="Wpis wygenerowany" />
            <h1>{title}</h1>
            <Date>{date}</Date>
            <Image src={articlePhoto}/>
            <Content>{content}</Content>
            <Link to="/">Powrót do strony głównej</Link>
          </Container>
        </SectionA>
      </Layout>
    )
  }
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      date
      featured_media {
        source_url
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

// function getPhoto(data) {
//   if (data.photo) {
//     const photo = data.photo.source_url;
//     return photo;
//   }
// }

function formatDate(data) { 
  return data.slice(0, 10).split("-").reverse().join("-");
}